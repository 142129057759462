<template>
  <div>
    <b-card>
      <progress-bar-status :progress="1" :status="currentDataDoctor.status" />
    </b-card>

    <div class="bg-white rounded mt-3 mx-0" v-if="mode !== 'registration'">
      <ViewDoctorQuestionnaire
        :registration_id="currentDataDoctor.registration_id"
        :mode="mode"
        :editable="false"
      />
    </div>

    <div class="bg-white rounded mt-3 mx-0" v-if="mode === 'registration'">
      <WaitingListTableDoctorQuestionnaire
        :registration_id="registration_id"
        :editable="false"
        questionnatireType="edit"
        :checkboxDisabled="true"
        mode="dokter"
      />
    </div>

    <div class="bg-white rounded mt-3 p-5 mx-0">
      <div class="d-flex flex-wrap align-items-start justify-content-between">
        <div class="mr-auto">
          <h2 v-if="mode === 'registration'">Diisi Oleh Dokter</h2>
          <h2 v-if="mode === 'edit' || mode === 'detail'">
            Hasil Pemeriksaan Dokter
          </h2>
          <div v-if="editableData.status === 'Rejected'">
            <!-- Badge for low blood pressure -->
            <b-badge
              v-if="
                editableData.systolic &&
                editableData.diastolic &&
                (editableData.systolic < 100 || editableData.diastolic < 60)
              "
              variant="danger"
              class="mr-2 mb-2"
            >
              Tensi rendah
            </b-badge>

            <!-- Badge for high blood pressure -->
            <b-badge
              v-if="
                editableData.systolic &&
                editableData.diastolic &&
                (editableData.systolic > 180 || editableData.diastolic > 100)
              "
              variant="danger"
              class="mr-2 mb-2"
            >
              Tensi tinggi
            </b-badge>

            <!-- Badge for low weight -->
            <b-badge
              v-if="editableData.weight && editableData.weight < 45"
              variant="danger"
              class="mr-2 mb-2"
            >
              Berat badan kurang
            </b-badge>

            <!-- Badge for selected rejection reason -->
            <b-badge
              v-if="
                editableData.rejection_reason !== 'tidak ada' &&
                editableData.rejection_reason
              "
              variant="danger"
              class="mr-2 mb-2"
            >
              {{
                rejectionReasons.find(
                  (reason) => reason.value === editableData.rejection_reason
                )?.text
              }}
            </b-badge>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <div v-if="mode === 'detail'">
            <b-button @click="setMode('edit')" variant="primary">
              Edit
            </b-button>
          </div>
          <div v-else-if="mode === 'edit'">
            <b-button @click="cancelEdit" variant="danger"> Batal </b-button>
            <b-button
              @click="saveChanges"
              variant="success"
              :disabled="isBusy || !isFormValid"
              class="ml-2"
            >
              <b-spinner small v-if="isBusy" />
              Simpan
            </b-button>
          </div>
        </div>
      </div>
      <hr />
      <b-form @submit.prevent="saveChanges">
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Tekanan Darah">
              <b-input-group>
                <b-form-input
                  v-model="editableData.systolic"
                  :disabled="mode === 'detail'"
                  placeholder="Systolic"
                  type="number"
                />
                <b-input-group-text>/</b-input-group-text>
                <b-form-input
                  v-model="editableData.diastolic"
                  :disabled="mode === 'detail'"
                  placeholder="Diastolic"
                  type="number"
                />
                <b-input-group-text>mmHg</b-input-group-text>
              </b-input-group>
            </b-form-group>
            <b-form-group label="Berat Badan">
              <b-form-input
                v-model="editableData.weight"
                :disabled="mode === 'detail'"
                placeholder="Kg"
                type="number"
              />
            </b-form-group>
            <b-form-group label="Tinggi Badan">
              <b-form-input
                v-model="editableData.height"
                :disabled="mode === 'detail'"
                placeholder="cm"
                type="number"
              />
            </b-form-group>
            <b-form-group label="Keadaan Umum">
              <b-form-input
                v-model="editableData.general_condition"
                :disabled="mode === 'detail'"
                placeholder="Keadaan Umum"
              />
            </b-form-group>
            <b-form-group label="Alasan Penolakan">
              <b-form-select
                v-model="editableData.rejection_reason"
                :disabled="mode === 'detail'"
                :options="rejectionReasons"
              />
            </b-form-group>
            <b-form-group label="Denyut Nadi">
              <b-form-input
                v-model="editableData.pulse"
                :disabled="mode === 'detail'"
                placeholder="bpm"
                type="number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Suhu">
              <b-form-input
                v-model="editableData.temperature"
                :disabled="mode === 'detail'"
                placeholder="°C"
                type="number"
                step="0.1"
              />
            </b-form-group>
            <b-form-group label="Riwayat Medis">
              <b-form-textarea
                v-model="editableData.medical_history"
                :disabled="mode === 'detail'"
                rows="3"
              />
            </b-form-group>
            <b-form-group label="Metode Pengambilan Darah">
              <b-form-select
                v-model="editableData.collection_method"
                :disabled="mode === 'detail'"
                :options="collectionMethods"
              />
            </b-form-group>
            <b-form-group label="Kantong">
              <b-form-select
                v-model="editableData.bag"
                :disabled="mode === 'detail'"
                :options="bagTypes"
              />
            </b-form-group>
            <b-form-group label="Diambil Sebanyak">
              <b-form-radio-group
                v-model="editableData.cc"
                :options="ccOptions"
                :disabled="mode === 'detail'"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <div class="d-flex justify-content-end">
          <b-button
            v-if="mode === 'registration'"
            @click="saveChanges"
            class="mt-3"
            variant="primary"
            :disabled="isBusy || !isFormValid"
          >
            <b-spinner small v-if="isBusy" />
            Daftar
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  BTable,
  BPagination,
  BSpinner,
  BCard,
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormRadioGroup,
  BInputGroup,
  BInputGroupText,
  BFormInvalidFeedback,
  BBadge,
} from "bootstrap-vue";
import ProgressBarStatus from "../../components/ProgressBarStatus.vue";
import manageDoctorAPI from "../../../../../../api/managedoctor/manageDoctorAPI";
import ViewDoctorQuestionnaire from "../../components/ViewDoctorQuestionnaire.vue";
import WaitingListTableDoctorQuestionnaire from "../../../registrationdonation/components/common/docter/ViewDoctorQuestionnaire.vue";

export default {
  name: "ViewDonationStatus",
  components: {
    BCol,
    BRow,
    BTable,
    BPagination,
    BSpinner,
    BCard,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BFormSelect,
    BFormRadioGroup,
    BInputGroup,
    BInputGroupText,
    BFormInvalidFeedback,
    BBadge,
    ProgressBarStatus,
    ViewDoctorQuestionnaire,
    WaitingListTableDoctorQuestionnaire,
  },
  data() {
    return {
      labs: ["Registrasi", "Dokter", "HB", "Aftaf"],
      currentDataDoctor: null,
      registration_id: null,
      editableData: {
        systolic: null,
        diastolic: null,
        pulse: null,
        weight: null,
        height: null,
        general_condition: null,
        temperature: null,
        medical_history: null,
        collection_method: null,
        bag: null,
        cc: null,
        status: null,
        reason_rejected: null,
        rejection_reason: "tidak ada",
      },
      routeName: "",
      isBusy: false,
      collectionMethods: [
        { value: "Biasa", text: "Biasa" },
        { value: "Aferesis", text: "Aferesis" },
        { value: "Autologus", text: "Autologus" },
      ],
      bagTypes: [
        { value: "DB", text: "DB" },
        { value: "TR", text: "TR" },
      ],
      ccOptions: [
        { value: "350", text: "350 cc" },
        { value: "450", text: "450 cc" },
      ],
      rejectionReasons: [
        { value: "tidak ada", text: "Tidak Ada" },
        { value: "menyusui", text: "Menyusui" },
        { value: "operasi", text: "Operasi" },
        { value: "minum obat", text: "Minum obat" },
        { value: "kurang tidur", text: "Kurang tidur" },
        { value: "riwayat vaksin", text: "Riwayat vaksin" },
        { value: "lain-lain", text: "lain-lain" },
      ],
      mode: "detail", // Default mode
      originalData: {},
    };
  },
  computed: {
    isBloodPressureNormal() {
      const { systolic, diastolic } = this.editableData;
      return (
        systolic &&
        diastolic &&
        systolic >= 100 &&
        systolic <= 160 &&
        diastolic >= 60 &&
        diastolic <= 100
      );
    },
    isWeightNormal() {
      return this.editableData.weight && this.editableData.weight >= 45;
    },
    isFormValid() {
      if (!this.isBloodPressureNormal) {
        return this.editableData.systolic && this.editableData.diastolic;
      }
      if (!this.isWeightNormal) {
        return this.editableData.weight;
      }
      if (
        this.editableData.rejection_reason &&
        this.editableData.rejection_reason !== "tidak ada"
      ) {
        return true;
      }
      // If all above conditions are false, check if all fields are filled
      return (
        this.editableData.systolic &&
        this.editableData.diastolic &&
        this.editableData.pulse &&
        this.editableData.weight &&
        this.editableData.height &&
        this.editableData.general_condition &&
        this.editableData.temperature &&
        this.editableData.collection_method &&
        this.editableData.bag &&
        this.editableData.cc
      );
    },
  },
  watch: {
    "editableData.systolic": "updateStatus",
    "editableData.diastolic": "updateStatus",
    "editableData.weight": "updateStatus",
    "editableData.rejection_reason": "updateStatus",
  },
  mounted() {
    this.setInitialMode();
    if (this.mode === "registration") {
      this.currentDataDoctor = {}; // Initialize doctor data for registration mode
      this.registration_id = this.$route.params.id;
    } else {
      this.getDoctor();
    }
  },
  methods: {
    setInitialMode() {
      const route = this.$route.path;
      if (route.includes("/dashboards/dokter-edit/")) {
        this.mode = "edit";
      } else if (route.includes("/dashboards/dokter-registrasi/")) {
        this.mode = "registration";
      } else {
        this.mode = "detail";
      }
      console.log("Current mode:", this.mode);
    },
    async getDoctor() {
      try {
        this.isBusy = true;
        const { data } = await manageDoctorAPI.getDetail(this.$route.params.id);
        this.currentDataDoctor = data.data;
        this.updateEditableData();
      } catch (error) {
        console.log(error);
      } finally {
        this.isBusy = false;
      }
    },
    updateEditableData() {
      this.editableData = { ...this.currentDataDoctor };
      if (this.editableData.blood_pressure) {
        const [systolic, diastolic] =
          this.editableData.blood_pressure.split("/");
        this.editableData.systolic = systolic;
        this.editableData.diastolic = diastolic;
      }
    },
    setMode(newMode) {
      this.mode = newMode;
      if (newMode === "edit") {
        this.originalData = { ...this.editableData };
        this.updateEditableData();
      }
    },
    cancelEdit() {
      this.editableData = JSON.parse(JSON.stringify(this.originalData));
      this.mode = "detail";
    },
    updateStatus() {
      if (!this.isBloodPressureNormal) {
        if (
          this.editableData.systolic < 100 ||
          this.editableData.diastolic < 60
        ) {
          this.editableData.status = "Rejected";
          this.editableData.reason_rejected = "tensi rendah";
        } else if (
          this.editableData.systolic > 180 ||
          this.editableData.diastolic > 100
        ) {
          this.editableData.status = "Rejected";
          this.editableData.reason_rejected = "tensi tinggi";
        }
      } else if (!this.isWeightNormal) {
        this.editableData.status = "Rejected";
        this.editableData.reason_rejected = "berat badan kurang";
      } else if (
        this.editableData.rejection_reason &&
        this.editableData.rejection_reason !== "tidak ada"
      ) {
        this.editableData.status = "Rejected";
        this.editableData.reason_rejected = this.editableData.rejection_reason;
      } else {
        this.editableData.status = "Approved";
        this.editableData.reason_rejected = null;
      }
    },
    async saveChanges() {
      if (!this.isFormValid) {
        this.showErrorToast("Harap lengkapi semua kolom");
        return;
      }
      this.isBusy = true;
      try {
        const { systolic, diastolic, status, reason_rejected } =
          this.editableData;
        const registration_id =
          this.mode === "registration"
            ? this.$route.params.id
            : this.currentDataDoctor.registration_id;
        const dataToSave = {
          ...this.editableData,
          id: this.currentDataDoctor.id,
          registration_id: registration_id,
          blood_pressure: `${systolic}/${diastolic}`,
          status,
          reason_rejected,
        };
        const apiMethod =
          this.mode === "registration"
            ? manageDoctorAPI.add(dataToSave)
            : manageDoctorAPI.edit(dataToSave);
        const response = await apiMethod;

        this.currentDataDoctor.status = status;
        this.updateEditableData();

        if (this.mode === "registration") {
          this.$router.push({ name: "dokter" });
        }
        this.$bvToast.toast("Perubahan berhasil disimpan", {
          title: "Sukses",
          variant: "success",
          solid: true,
        });
        if (this.mode === "edit") {
          this.mode = "detail";
        }
      } catch (error) {
        console.error("Error saving changes:", error);
        this.$bvToast.toast("Gagal menyimpan perubahan", {
          title: "Error",
          variant: "danger",
          solid: true,
        });
      } finally {
        this.isBusy = false;
        this.getDoctor();
      }
    },
    showErrorToast(message) {
      this.$bvToast.toast(message, {
        title: "Error",
        variant: "danger",
        solid: true,
      });
    },
  },
};
</script>

<style scoped>
/* Tambahkan gaya CSS jika diperlukan */
</style>
