<template>
  <div class="bg-white p-3 rounded mt-3">
    <b-table
      id="questionnaire-table"
      striped
      hover
      responsive
      :items="items"
      :fields="fields"
      :per-page="perPage"
      :current-page="currentPage"
      small
    >
      <template v-slot:cell(answer)="data">
        <b-form-checkbox-group
          class="checkbox-group-answer d-flex"
          style="gap: 1.5rem; width: max-content"
          v-model="data.item.answer"
          @change="updateAnswer(data.item.id, data.item.answer)"
          stacked
        >
          <b-form-checkbox
            v-for="option in checkboxOptions"
            :key="option.value"
            :value="option.value"
            disabled
          >
            {{ option.label }}
          </b-form-checkbox>
        </b-form-checkbox-group>
      </template>
    </b-table>

    <div class="d-flex flex-column flex-sm-row justify-content-between pl-3">
      <b-pagination
        v-model="currentPage"
        :total-rows="totalRows"
        :per-page="perPage"
        aria-controls="questionnaire-table"
        small
        class="mb-2 mb-sm-0"
      />
      <!-- <b-button
        v-if="mode !== 'registration'"
        variant="primary"
        @click="showModal = true"
        class="mt-2 mt-sm-0"
      >
        Simpan
      </b-button> -->
    </div>
    <img v-if="signatureImage" :src="signatureImage" alt="" />

    <b-modal
      id="modal"
      class="bg-black-0 rounded pr-16 pr-sm-32 mx-0 d-flex column mt-3"
      v-model="showModal"
      centered
      hide-header
      hide-footer
    >
      <h5 class="mb-0">Konfirmasi Edit Kuesioner Pendonor</h5>
      <p>
        Apakah anda akan melakukan edit kuesioner pada Pendonor Atas Nama
        <span class="font-weight-bold">{{ dataPendonor.nama }}</span>
        ?
      </p>
      <div class="d-flex justify-content-end flex-wrap mt-32">
        <b-button variant="text" @click="showModal = false">Tutup</b-button>
        <b-button
          class="ml-16"
          variant="primary"
          :disabled="isLoading"
          @click="handleAction"
        >
          Ya, Edit
          <b-spinner small label="Loading" v-if="isLoading" />
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BTable,
  BModal,
  BPagination,
  BFormCheckbox,
  BFormCheckboxGroup,
  BSpinner,
} from "bootstrap-vue";
import manageQuestionnaireAPI from "@/api/managequestionnaire/manageQuestionnaireAPI";
import manageDoctorAPI from "@/api/managedoctor/manageDoctorAPI";
import AktivasiPendonorService from "@/api/managependonor/managePendonorAPI";

export default {
  components: {
    BButton,
    BTable,
    BModal,
    BPagination,
    BFormCheckbox,
    BFormCheckboxGroup,
    BSpinner,
  },
  data() {
    return {
      isLoading: false,
      currentPage: 1,
      totalRows: 0,
      perPage: 21,
      dataPendonor: null,
      showModal: false,
      fields: [
        { key: "id", label: "ID", sortable: true },
        { key: "description", label: "Description", sortable: true },
        { key: "answer", label: "Answer" },
      ],
      items: [],
      answer: [],
      checkboxOptions: [
        { value: "YA", label: "Ya" },
        { value: "TIDAK", label: "Tidak" },
      ],
      disabledOptions: {},
    };
  },
  props: {
    editable: { type: Boolean, required: true },
    mode: { type: String, default: "registration", required: true },
  },
  async mounted() {
    await this.initializeData();
  },
  methods: {
    async initializeData() {
      try {
        const pendonorId = this.$route.params.id;
        const pendonorResponse = await manageDoctorAPI.getDetail(pendonorId);
        this.dataPendonor = pendonorResponse.data.data;

        await this.fetchQuestionnaireList(this.currentPage);
        await this.fetchQuestionnaireData();
      } catch (error) {
        console.error("Error initializing data:", error);
      }
    },
    async fetchQuestionnaireList(pageNumber) {
      try {
        const response = await manageQuestionnaireAPI.getAll({
          per_page: 50,
          page: pageNumber,
        });
        this.items = response.data.data.data.map((item) => ({
          id: item.order,
          description: item.description,
          answer: [],
        }));
        this.totalRows = response.data.data.total;
        this.updateItemsWithAnswers();
      } catch (error) {
        console.error("Error fetching questionnaire list:", error);
      }
    },
    async fetchQuestionnaireData() {
      try {
        const registrationId =
          this.mode === "registration"
            ? this.$route.params.id
            : this.dataPendonor.registration_id;

        const response =
          await AktivasiPendonorService.listRegistrationQuestionnaire(
            registrationId
          );

        let dataAnswer = response.data.data.data_answer;

        // Parse the double-escaped JSON string
        if (typeof dataAnswer === "string") {
          // First parse the outer escaped JSON
          dataAnswer = JSON.parse(dataAnswer);

          // If it's still a string, parse it again to get the final array
          if (typeof dataAnswer === "string") {
            dataAnswer = JSON.parse(dataAnswer);
          }
        }

        // Handle the parsed answer and set it to the component's state
        this.signatureImage = response.data.data.signature_image;
        this.answer = Array.isArray(dataAnswer) ? dataAnswer : [];
        this.updateItemsWithAnswers();
      } catch (error) {
        console.error("Error fetching questionnaire data:", error);
      }
    },
    updateItemsWithAnswers() {
      this.items = this.items.map((item) => {
        const answerObj = this.answer.find(
          (ans) => Number(ans.question_id) === item.id
        );
        const isAnswered = !!answerObj?.answer;

        this.disabledOptions[item.id] = {
          YA: isAnswered && answerObj.answer === "TIDAK",
          TIDAK: isAnswered && answerObj.answer === "YA",
        };

        return { ...item, answer: isAnswered ? [answerObj.answer] : [] };
      });
    },
    isCheckboxDisabled(questionId, value) {
      return this.disabledOptions[questionId]?.[value] ?? false;
    },
    updateAnswer(questionId, answer) {
      if (answer.length > 1) answer = [answer.pop()];

      const itemIndex = this.items.findIndex((item) => item.id === questionId);
      if (itemIndex === -1) return;

      this.$set(this.items[itemIndex], "answer", answer);
      const existingAnswer = this.answer.find(
        (ans) => Number(ans.question_id) === questionId
      );

      if (existingAnswer) {
        existingAnswer.answer = answer[0];
      } else {
        this.answer.push({ question_id: questionId, answer: answer[0] });
      }

      this.disabledOptions[questionId] = {
        YA: answer[0] === "TIDAK",
        TIDAK: answer[0] === "YA",
      };
    },
    async handleAction() {
      try {
        const registrationId =
          this.mode === "registration"
            ? this.$route.params.id
            : this.dataPendonor.registration_id;

        const payload = {
          registration_id: registrationId,
          data_answer: this.answer.map((ans) => ({
            question_id: ans.question_id,
            answer: ans.answer,
          })),
        };

        this.isLoading = true;
        const response =
          await AktivasiPendonorService.updateRegistrationQuestion(payload);

        if (response && response.status === 200) {
          console.log("Update successful:", response.data);
        } else {
          console.error("Update failed:", response);
        }

        await this.fetchQuestionnaireData();
      } catch (error) {
        console.error("Error updating questionnaire:", error);
      } finally {
        this.isLoading = false;
        this.showModal = false;
      }
    },
  },
};
</script>

<style scoped>
@media (max-width: 720px) {
  .checkbox-group-answer {
    flex-direction: column;
    gap: 0 !important;
  }
}
</style>
